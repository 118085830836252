import React from 'react'
import { Stack, Main } from '@layout'
import { Text } from 'theme-ui'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Section from '@components/Section'

export default () => (
  <>
    <Seo title='Web Agreement' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Điều khoản sử dụng dịch vụ"
          subheader='Bằng cách truy cập trang web tại https://outdoors.genkihub.com , bạn đồng ý bị ràng buộc bởi các điều khoản dịch vụ này, tất cả các luật và quy định hiện hành và đồng ý rằng bạn có trách nhiệm tuân thủ bất kỳ luật pháp hiện hành nào. Nếu bạn không đồng ý với bất kỳ điều khoản nào trong số này, bạn bị cấm sử dụng hoặc truy cập trang web này. Các tài liệu trong trang web này được bảo vệ bởi luật bản quyền và thương hiệu hiện hành.'
        />
        <Divider />
        <Section>
          <Text variant='p'>
            Trang web có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào trong Quy định và Điều khoản sử dụng, vào bất cứ lúc nào.
          </Text>
          <Text variant='p'>
            Các thay đổi có hiệu lực ngay khi được đăng trên trang web mà không cần thông báo trước.
          </Text>
          <Text variant='p'>
            Và khi quý khách tiếp tục sử dụng trang web, sau khi các thay đổi về quy định và điều kiện được đăng tải, có nghĩa là quý khách chấp nhận với những thay đổi đó.
          </Text>
          <Text variant='p'>
            Quý khách vui lòng kiểm tra thường xuyên để cập nhật những thay đổi của chúng tôi.
          </Text>
          <Text variant='h2'>
            GIẤY PHÉP SỬ DỤNG
          </Text>
          <Text variant='p'>
            Bạn được tải tài liệu được cung cấp miễn phí trên trang web để nghiên cứu và sử dụng cho cá nhân. Đây là việc cấp quyền sử dụng, không phải chuyển nhượng quyền sở hữu và bạn không được:
              <ul>
                <li>Sửa đổi hoặc sao chép các tài liệu;</li>
                <li>Sử dụng các tài liệu cho bất kỳ mục đích thương mại, hoặc chia sẻ trên các phương tiện thông tin đại chúng (thương mại hoặc phi thương mại).</li>
                <li>Xóa mọi bản quyền hoặc ký hiệu độc quyền khác khỏi tài liệu.</li>
              </ul>
            Giấy phép này sẽ tự động chấm dứt nếu bạn vi phạm bất kỳ hạn chế nào trong số này. Khi chấm dứt việc xem các tài liệu này hoặc khi chấm dứt giấy phép này, bạn phải hủy mọi tài liệu đã tải xuống mà bạn sở hữu cho dù ở định dạng điện tử hoặc in.
          </Text>
          <Text variant='h2'>
            KHƯỚC TỪ
          </Text>
          <Text variant='p'>
            Các tài liệu trên Outdoors không phải là những chẩn đoán y học, cũng như là các liệu trình điều trị bệnh thay thế. Vì thế các tốt nhất bạn nên tham khảo ý kiến của các bác sĩ trước khi bắt đầu bất cứ chương trình giảm cân hay nhịn ăn nào, cũng như các chương trình cải thiện sức khỏe khác được đề cập trên trang web. Outdoors sẽ không chịu bất cứ trách nhiệm nào khi bạn đọc tự ý sử dụng các chương trình sức khỏe mà không tuân thủ theo các khuyến cáo của chương trình đề ra.
          </Text>
          <Text variant='p'>
            Thông tin đăng trên Outdoors tổng hợp từ các nghiên cứu khoa học và có những thông tin thể hiện quan điểm riêng của tác giả. Điều này có thể khác với quan điểm của bác sĩ và chuyên gia y té, chăm sóc sức khỏe. Vì cơ địa của mỗi người mỗi khác và có thể bị dị ứng với những chất, thực phẩm, sản phẩm… đề cập trong bài viết cho nên bạn cần suy xét kỹ lưỡng trước khi áp dụng những đề xuất, gợi ý, lời khuyên của tác giả. Người áp dụng phải chịu hoàn toàn trách nhiệm về quyết định của mình.
          </Text>
        </Section>
      </Main>
    </Stack>
  </>
)
